import React, { Fragment, useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import moment from "moment";
import userImg from "../../assets/img/user.jpg";
import vehicleImg from "../../assets/img/vehicle.jpg";
import ThemeContext from "../../context/themeContext";
import Swal from "../Swal";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";

const TopEmployees = () => {
  const [responseData, setResponsedata] = useState({});
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [filterType, setFilterType] = useState(2);

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    const getTopEmployeesData = async () => {
      await api
        .post(
          "api/dashboard/top-service-writer",
          { sw_type: filterType },
          {
            headers: {
              Authorization: `Bearer ${authCtx?.userData?.token}`,
            },
          }
        )
        .then((res) => {
          setResponsedata(res.data.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    };

    getTopEmployeesData();
  }, [filterType]);

  const filterOnChange = (e) => {
    setFilterType(e.target.value);
  };

  const showEmployeeDetail = async (data) => {
    if (data.id != undefined) {
      await api
        .post(
          `api/dashboard/get-top-employees`,
          { user_id: data.id },
          {
            headers: {
              Authorization: `Bearer ${authCtx?.userData?.token}`,
            },
          }
        )
        .then((res) => {
          setEmployeeDetail(res.data.data);
          themeCtx.showDropShadowHandler();
        })
        .catch((error) => {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        });
    }
  };

  const closeHandler = () => {
    themeCtx.hideDropShadowHandler();
    setEmployeeDetail(null);
  };

  return (
    <Fragment>
      <div className="dashboard-card mb-24 mb-xl-0">
        <div className="dashboard-card-filter">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Top Employees</h4>
            </div>
            <div className="col-auto pe-0">
              <div className="dashboard-card-select">
                <div className="form-group">
                  <select className="form-select">
                    <option value="1">By Total Won</option>
                    <option value="2">By Appraisal</option>
                    <option value="3">By Total Amount</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="dashboard-card-select">
                <div className="form-group">
                  <select
                    className="form-select"
                    onChange={filterOnChange}
                    defaultValue={filterType}
                  >
                    <option value="1">This day</option>
                    <option value="2">This week</option>
                    <option value="3">This month</option>
                    <option value="4">This year</option>
                    <option value="5">Range</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-top-employees">
          <table className="table">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Appraisals</th>
                <th>Total Won</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {responseData?.topServiceWriter &&
              responseData?.topServiceWriter.length != 0 ? (
                responseData?.topServiceWriter.map((data) => (
                  <tr key={data.id}>
                    <td>
                      <div
                        className="dashboard-top-employees-profile"
                        onClick={() => showEmployeeDetail(data)}
                      >
                        <img
                          src={data?.image != "" ? data?.image : userImg}
                          alt=""
                        />
                      </div>
                      {data?.name}
                    </td>
                    <td>{data?.appraisals}</td>
                    <td className="total-won">{data?.totalWon}</td>
                    <td className="total-amount">${data?.totalAmount}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={4}>No Employee Found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={`dashboard-popup ${employeeDetail != null ? "show" : ""}`}
      >
        <div className="top-employee-popup dashboard-popup-inner">
          <button
            type="button"
            className="dashboard-popup-close"
            onClick={() => closeHandler()}
          >
            <i className="icon icon-close"></i>
          </button>
          <div className="top-employee-popup-header">
            <div className="row align-items-center">
              <div className="col">
                <div className="top-employee-user">
                  <div className="top-employee-user-image">
                    <img
                      src={
                        employeeDetail?.userImage != ""
                          ? employeeDetail?.userImage
                          : userImg
                      }
                      alt=""
                    />
                  </div>
                  <div className="top-employee-user-info">
                    <h2>{employeeDetail?.userName}</h2>
                    <p>Service Writer</p>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <Link
                  to={`employees/view/${employeeDetail?.user_id}`}
                  className="btn btn-parrot"
                >
                  See Profile
                </Link>
              </div>
            </div>
          </div>
          <h5 className="dashboard-popup-title mb-20">Overview</h5>
          <div className="top-employee-count-wrap mb-20">
            <div className="row">
              <div className="col-md-4">
                <div className="top-employee-count is-total-won">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        {employeeDetail?.totalWon}
                      </h5>
                      <span className="top-employee-count-label">
                        Total Won
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="top-employee-count is-total-appraisals">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        {employeeDetail?.appraisals}
                      </h5>
                      <span className="top-employee-count-label">
                        Appraisal
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="top-employee-count is-total-amount">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-dollar"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        ${employeeDetail?.totalAmount}
                      </h5>
                      <span className="top-employee-count-label">
                        Total Amount
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-16">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="dashboard-popup-title mb-0">
                  Best deals of {employeeDetail?.userName}
                </h4>
              </div>
              <div className="col-auto">
                <Link className="btn btn-outline-grey" to={routes.Appraisals}>
                  See all
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-card">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Vehicle <span className="table-sort"></span>
                  </th>
                  <th>
                    VIN Number <span className="table-sort"></span>
                  </th>
                  <th>
                    submitted Date <span className="table-sort"></span>
                  </th>
                  <th>
                    Offer Amount <span className="table-sort"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {employeeDetail?.topServiceWriter &&
                employeeDetail?.topServiceWriter.length != 0 ? (
                  employeeDetail?.topServiceWriter.map((data) => (
                    <tr key={data.id}>
                      <td>
                        <div className="table-vehicle-image">
                          <img
                            src={
                              data?.vehicleImage != ""
                                ? data?.vehicleImage
                                : vehicleImg
                            }
                            alt=""
                          />
                        </div>
                        {data?.make} {data?.model} {data?.year}
                      </td>
                      <td>{data?.vin}</td>
                      <td>{moment(data?.created_at).format("MMMM D, YYYY")}</td>
                      <td>${data?.deals_price}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={4}>No Service Writer Found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopEmployees;
