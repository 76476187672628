import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import userImg from "../../assets/img/user.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(10, "Phone number is to long.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is invalid."
    ),
});

const Profile = () => {
  const authCtx = useContext(AuthContext);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState({ imageUrl: "" });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: authCtx.userData?.name,
      email: authCtx.userData?.email,
      mobile_no: authCtx.userData?.mobile_no,
    },
  });

  const uploadedImage = watch("image");
  useEffect(() => {
    if (uploadedImage != undefined && uploadedImage.length != 0) {
      setPreviewImage({ imageUrl: URL.createObjectURL(uploadedImage[0]) });
    }
  }, [uploadedImage]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formValue.mobile_no);
    formData.append("old_password", formValue.old_password);
    formData.append("new_password", formValue.new_password);
    if (formValue.image[0] != null) {
      formData.append("image", formValue.image[0]);
    }
    await api
      .post("api/users/update-user-profile", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          const oldData = {
            ...authCtx.userData,
            ...response.data.data,
          };
          authCtx.login(oldData);
          Swal.fire(response.data.message, "", "success");
          setIsEdit(false);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="appraisal-head-wrap mb-34">
          <div className="row">
            <div className="col">
              <div className="appraisal-head-title align-items-start">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon icon-back-aero"></i>
                </button>
                <div className="page-head">
                  <h2 className="page-title">My Profile</h2>
                  <p className="page-dec">Fill the required informations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification edit-profile my-edit-profile mb-24">
          <div className="manager-details-inner mt-0">
            <div className="row align-items-center">
              <div className="col managercol">
                <div className="manager-details-user">
                  <div className="manager-image">
                    <img
                      src={
                        previewImage.imageUrl
                          ? previewImage.imageUrl
                          : authCtx.userData?.image != ""
                          ? authCtx.userData?.image
                          : userImg
                      }
                      alt=""
                    />
                    {isEdit && (
                      <>
                        <i className="icon icon-add-picture"></i>
                        <input
                          type="file"
                          {...register("image")}
                          accept="image/png, image/jpg, image/jpeg"
                        />
                      </>
                    )}
                  </div>
                  <div className="manager-info">
                    <h4>{authCtx.userData?.name}</h4>
                    <span>
                      {authCtx.userData?.email} • {authCtx.userData?.mobile_no}
                    </span>
                  </div>
                </div>
              </div>

              {isEdit ? (
                <div className="col-auto text-end managercol myprofilecol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-primary d-inline-flex align-items-center"
                  >
                    <i className="icon icon-save"></i> Save Changes
                  </button>
                </div>
              ) : (
                <div className="col-auto managercol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(true)}
                  >
                    <i className="icon icon-edit"></i> Edit Profile
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="appraisal-form-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("name")}
                  />
                  {errors.name?.message != undefined && (
                    <span className="form-error">{errors.name?.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("mobile_no")}
                  />
                  {errors.mobile_no?.message != undefined && (
                    <span className="form-error">
                      {errors.mobile_no?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    readOnly
                    {...register("email")}
                  />
                </div>
              </div>
              {isEdit && (
                <>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Old Password"
                        disabled={!isEdit}
                        {...register("old_password")}
                      />
                      {errors.old_password?.message != undefined && (
                        <span className="form-error">
                          {errors.old_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                        disabled={!isEdit}
                        {...register("new_password")}
                      />
                      {errors.new_password?.message != undefined && (
                        <span className="form-error">
                          {errors.new_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default Profile;
