import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import routes from "../../constants/routesConstants";
import logoImg from "../../assets/img/logo.svg";
import userImg from "../../assets/img/user.jpg";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    if (themeCtx.isContactPopupShow) {
      themeCtx.contactPopupToggle();
    } else if (themeCtx.showDropShadow) {
      themeCtx.hideDropShadowHandler();
    }
  }, [location]);

  const logoutHandler = () => {
    authCtx.logout();
    navigate(routes.Home);
  };

  return (
    <div className="dashboard-navbar">
      <div className="dashboard-navbar-logo">
        <div
          className={`menuIcon ${themeCtx.isSidebarOpen ? "show" : ""}`}
          onClick={() => themeCtx.toggleSidebar()}
        >
          <span></span>
        </div>
        <a href="#">
          <img src={logoImg} alt="" />
        </a>
      </div>
      <div className="dashboard-navbar-search">
        <div className="form-group mb-0">
          <input
            type="text"
            className="form-control"
            placeholder="Search for a car or a model"
          />
        </div>
      </div>
      <div className="dashboard-links d-none d-xl-inline-block">
        <ul>
          <li>
            <a href="#">Get Help</a>
          </li>
          <li onClick={() => themeCtx.contactPopupToggle()}>
            <a href="#">Contact Us</a>
          </li>
          {/* <li>
            <a href="#">Go Pro</a>
          </li> */}
        </ul>
      </div>
      <div className="dashboard-button">
        <div className="dashboard-button-box">
          <Link to={routes.AppraisalCreate} className="btn btn-pink">
            <i className="icon icon-add"></i>New Appraisal
          </Link>
        </div>
        <div className="dashboard-button-box">
          <a href="#" className="btn-icon">
            <i className="icon icon-notification is-new"></i>
          </a>
        </div>
        <div className="dashboard-button-box">
          <div
            className="dashboard-profile-dropdown"
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <button type="button" className="dashboard-profile-dropdown-button">
              <img
                src={
                  authCtx?.userData?.image != "" &&
                  authCtx?.userData?.image != undefined
                    ? authCtx?.userData?.image
                    : userImg
                }
                alt=""
              />
              <span>{authCtx?.userData?.name}</span>
              <i className="icon icon-chevron-down"></i>
            </button>
            <ul className={`${menuVisible == true ? "show" : ""}`}>
              <li>
                <Link to={routes.Profile}>My Profile</Link>
              </li>
              <li>
                <a href="#" onClick={() => logoutHandler()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
