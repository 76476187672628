import React from "react";
import Wrapper from "../../components/Wrapper";
import _ from "lodash";

const AppraisalHistory = () => {
  return (
    <>
      <Wrapper></Wrapper>
    </>
  );
};

export default AppraisalHistory;
