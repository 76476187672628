import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import api from "../../api";
import vehicleImg from "../../assets/img/vehicle.jpg";
import userImg from "../../assets/img/user.jpg";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "../Swal";

const LatestAppraisals = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState(2);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    getlatestAppraisals({}, 1);
  }, [filterType]);

  const getlatestAppraisals = async (value, page) => {
    value.lp_type = filterType;
    await api
      .post(`api/dashboard/latest-appraisals?page=${page}`, value, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((res) => {
        setResponsedata(res.data.data);
        setPagination(res.data.data.paginate);
      })
      .catch((error) => {
        Swal.fire({
          title: "Opps!",
          icon: "error",
          text: "Sorry we are not able to complete your request at this time. Please try again later.",
        });
      });
  };

  const sort = (field, currentField, sort, page) => {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        getlatestAppraisals(
          {
            field_name: field,
            order_by: "asc",
          },
          page
        );
      } else {
        setSortType("desc");
        getlatestAppraisals(
          {
            field_name: field,
            order_by: "desc",
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      getlatestAppraisals(
        {
          field_name: field,
          order_by: "desc",
        },
        page
      );
    }
  };

  const onPaginationFetch = (e) => {
    getlatestAppraisals(
      { field_name: sortField, order_by: sortType },
      e.selected + 1
    );
  };

  const filterOnChange = (e) => {
    setFilterType(e.target.value);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/leads/delete-lead",
              { lead_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx?.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                getlatestAppraisals({}, 1);
                Swal.fire("Deleted!", response.data.message, "success");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  return (
    <div className="dashboard-latest-appraisals">
      <div className="dashboard-card">
        <div className="dashboard-card-filter">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Latest Appraisals</h4>
            </div>
            <div className="col-auto">
              <div className="dashboard-card-select">
                <div className="form-group">
                  <select
                    className="form-select"
                    onChange={filterOnChange}
                    defaultValue={filterType}
                  >
                    <option value="1">This day</option>
                    <option value="2">This week</option>
                    <option value="3">This month</option>
                    <option value="4">This year</option>
                    <option value="5">Range</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-latest-apraisals table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th width="30%">
                  Vehicle{" "}
                  <span
                    className={`table-sort ${
                      sortField == "model" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort("model", sortField, sortType, pagination.current)
                    }
                  ></span>
                </th>
                <th width="10%">
                  Customer Name{" "}
                  <span
                    className={`table-sort ${
                      sortField == "first_name" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort(
                        "first_name",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                  ></span>
                </th>
                <th width="12%">
                  VIN Number{" "}
                  <span
                    className={`table-sort ${
                      sortField == "vin" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort("vin", sortField, sortType, pagination.current)
                    }
                  ></span>
                </th>
                <th width="11%">
                  Status{" "}
                  <span
                    className={`table-sort ${
                      sortField == "status" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                  ></span>
                </th>
                <th width="10%">
                  Offer Amount{" "}
                  <span
                    className={`table-sort ${
                      sortField == "deals_price" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort(
                        "deals_price",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                  ></span>
                </th>
                <th width="12%">
                  Submitted Date{" "}
                  <span
                    className={`table-sort ${
                      sortField == "created_at" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                  ></span>
                </th>
                <th width="15%">
                  Added by{" "}
                  <span
                    className={`table-sort ${
                      sortField == "name" ? sortType : ""
                    }`}
                    onClick={() =>
                      sort("name", sortField, sortType, pagination.current)
                    }
                  ></span>
                </th>
                <th width="10%">Action</th>
              </tr>
            </thead>
            <tbody>
              {responseData?.latestAppraisals &&
              responseData?.latestAppraisals.length != 0 ? (
                responseData?.latestAppraisals.map((data) => (
                  <tr key={data.id}>
                    <td>
                      <div className="table-vehicle-image">
                        <img
                          src={
                            data?.vehicleImage != ""
                              ? data?.vehicleImage
                              : vehicleImg
                          }
                          alt=""
                        />
                      </div>
                      {data?.year} {data?.make} {data?.model}
                    </td>
                    <td>
                      {data?.first_name} {data?.last_name}
                    </td>
                    <td>{data?.vin}</td>
                    <td>
                      {data?.status == 0 && (
                        <span className="status-new">New</span>
                      )}
                      {data?.status == 1 && (
                        <span className="status-pending">Working</span>
                      )}
                      {data?.status == 2 && (
                        <span className="status-pending">Offer Made</span>
                      )}
                      {data?.status == 3 && (
                        <span className="status-won">Deal Trade in</span>
                      )}
                      {data?.status == 4 && (
                        <span className="status-won">Deal Purchase</span>
                      )}
                      {data?.status == 5 && (
                        <span className="status-rejected">No Deal</span>
                      )}
                    </td>
                    <td>
                      {data?.deals_price != null
                        ? "$" + data?.deals_price
                        : "No Offer"}
                    </td>
                    <td>{moment(data?.created_at).format("MMMM D, YYYY")}</td>
                    <td>
                      <div className="table-user-image">
                        <img
                          src={data?.image != "" ? data?.image : userImg}
                          alt=""
                        />
                      </div>
                      {data?.name}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn-icon"
                        onClick={() => deleteHandler(data?.id)}
                      >
                        <i className="icon icon-delete"></i>
                      </button>
                      {authCtx.userData?.user_type != 2 && (
                        <Link
                          to={`/appraisals/view/${data?.id}`}
                          className="btn-icon"
                        >
                          <i className="icon icon-edit"></i>
                        </Link>
                      )}
                      {authCtx.userData?.user_type != 0 &&
                        authCtx.userData?.user_type != 1 && (
                          <Link
                            to={`/appraisals/edit/${data?.id}`}
                            className="btn-icon"
                          >
                            <i className="icon icon-edit"></i>
                          </Link>
                        )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={7}>No Appraisal Found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </div>
  );
};

export default LatestAppraisals;
