import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import AuthContext from "../../context/authContext";
import carfaxLogo from "../../assets/img/carfax.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import api from "../../api";
import routes from "../../constants/routesConstants";
import _ from "lodash";
import Swal from "../Swal";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is invalid."
    )
    .min(10, "Phone number is to short.")
    .max(10, "Phone number is to long.")
    .required("Phone number is required."),
  vin: yup
    .string()
    .required("VIN number is required")
    .min(17, "Please Enter Minimum 17 Characters.")
    .max(17, "Please Enter Maximum 17 Characters."),
  year: yup.string().required("Year is required."),
  make: yup.string().required("Make is required."),
  model: yup.string().required("Model is required."),
  trim: yup.string().required("Trim is required."),
  mileage: yup.string().required("Mileage is required."),
});

const AppraisalCreate = () => {
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [makes, setMakes] = useState([]);
  const [managers, setManagers] = useState([]);
  const [serviceWriters, setServiceWriters] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [fieldLoading, setfieldLoading] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [autoFillMake, setAutoFillMake] = useState(false);
  const countRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("first_name", formValue.first_name);
    formData.append("last_name", formValue.last_name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formValue.mobile_no);
    formData.append("vin", formValue.vin);
    formData.append("year", formValue.year);
    formData.append("make", formValue.make);
    formData.append("model", formValue.model);
    formData.append("trim", formValue.trim);
    formData.append("mileage", formValue.mileage);
    formData.append("price", formValue.price);
    formData.append("interior_color", formValue.interior_color);
    formData.append("exterior_color", formValue.exterior_color);
    if (
      authCtx.userData?.user_type == 0 &&
      formValue.manager != "" &&
      formValue.added_id == ""
    ) {
      formData.append("added_id", formValue.manager);
    } else if (authCtx.userData?.user_type != 2 && formValue.added_id != "") {
      formData.append("added_id", formValue.added_id);
    }
    let files = [];
    formValue.image.forEach((file) => {
      files.push(file.file);
    });
    for (var i = 0; i < files.length; i++) {
      formData.append("image[]", files[i]);
    }
    await api
      .post("api/leads/add-lead", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Appraisals);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "image",
  });

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (image) =>
      image.forEach((file) => {
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      }),
  });

  const formData = watch();

  useEffect(() => {
    countRef.current = autoFill;
  }, [autoFill]);

  useEffect(() => {
    if (formData.vin) {
      if (formData.vin.length == 17) {
        setfieldLoading(true);
        api
          .get(`api/leads/chrome-data?vin=${formData.vin}`, {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          })
          .then((response) => {
            setfieldLoading(false);
            if (response.data.status) {
              if (response.data.data.year != "0") {
                if (response.data.data.year) {
                  setAutoFill(true);
                  setValue("year", response.data.data.year);
                }
                if (response.data.data.model != "") {
                  setValue("model", response.data.data.model);
                }
                if (response.data.data.trim != "") {
                  setValue("trim", response.data.data.trim);
                }
                if (response.data.data.make != "") {
                  setAutoFillMake(true);
                  setValue("make", response.data.data.make);
                } else {
                  setAutoFill(false);
                }
              } else {
                setAutoFill(false);
                setAutoFillMake(false);
              }
            } else {
              setAutoFill(false);
              setAutoFillMake(false);
            }
          })
          .catch((error) => {
            setfieldLoading(false);
          });
      } else {
        setAutoFill(false);
        setAutoFillMake(false);
      }
    }
  }, [formData.vin]);

  const yearChange = async (value, key) => {
    setfieldLoading(true);
    setValue("year", value);
    await api
      .get(`api/leads/chrome-data?getMake=true&modelYear=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        setModels([]);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setMakes([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
      });
  };

  const makeChange = async (value, key) => {
    setfieldLoading(true);
    makeRef.current = key;
    await api
      .get(
        `api/leads/chrome-data?getModel=true&modelYear=${formData.year}&modelMake=${value}`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setfieldLoading(false);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setModels([]);
      });
  };

  const modelChange = async (value, key) => {
    setfieldLoading(true);
    modelRef.current = key;
    await api
      .get(`api/leads/chrome-data?getTrim=true&modelId=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setTrims([]);
      });
  };

  const trimChange = (value, key) => {
    trimRef.current = key;
  };

  useEffect(() => {
    if (authCtx?.userData?.user_type == 0) {
      api
        .post(
          `api/users/get-manager`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let managerArray = [];
            if (response.data.data.length > 0) {
              response.data.data.map((element) => {
                managerArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setManagers(managerArray);
            } else {
              setManagers([]);
            }
          } else {
            setManagers([]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    } else if (authCtx.userData?.user_type == 1) {
      api
        .post(
          `api/users/get-service-write`,
          { user_id: authCtx.userData?.id },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let serviceWriterArray = [];
            if (response.data.data.length > 0) {
              response.data.data.map((element) => {
                serviceWriterArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setServiceWriters(serviceWriterArray);
            } else {
              setServiceWriters([]);
            }
          } else {
            setServiceWriters([]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  }, []);

  const onManagerChange = async (e) => {
    await api
      .post(
        `api/users/get-service-write`,
        { user_id: e.target.value },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let serviceWriterArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              serviceWriterArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setServiceWriters(serviceWriterArray);
          } else {
            setServiceWriters([]);
          }
        } else {
          setServiceWriters([]);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Wrapper>
        <div className="appraisal-head-wrap mb-34">
          <div className="row">
            <div className="col">
              <div className="appraisal-head-title">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon icon-back-aero"></i>
                </button>
                <h2 className="page-title mb-0">Add New appraisal</h2>
              </div>
            </div>
            <div className="col-auto">
              <div className="appraisal-head-buttons">
                <Link
                  to={routes.Appraisals}
                  className="btn btn-outline-secondary"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="login-inner add-new-appraisal-inner">
          <div className="secHead">
            <h5 className="secTitle mb-0">Add New appraisal</h5>
          </div>
          <div className="add-new-appraisal-card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="appraisal-form-detail customer-details">
                <div className="appraisal-form-head">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">Customers</h5>
                    </div>
                  </div>
                </div>
                <div className="appraisal-form-inner mb-24">
                  <div className="row">
                    {authCtx.userData?.user_type == 0 && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Sales Managers
                          </label>
                          <select
                            className="form-select"
                            {...register("manager")}
                            onChange={(e) => onManagerChange(e)}
                          >
                            <option value="">Select Managers</option>
                            {_.map(managers, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {authCtx.userData?.user_type != 2 && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Employees</label>
                          <select
                            className="form-select"
                            {...register("added_id")}
                          >
                            <option value="">Select Employee</option>
                            {_.map(serviceWriters, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("first_name")}
                        />
                        {errors.first_name?.message != undefined && (
                          <span className="form-error">
                            {errors.first_name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("last_name")}
                        />
                        {errors.last_name?.message != undefined && (
                          <span className="form-error">
                            {errors.last_name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Phone</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register("mobile_no")}
                        />
                        {errors.mobile_no?.message != undefined && (
                          <span className="form-error">
                            {errors.mobile_no?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          {...register("email")}
                        />
                        {errors.email?.message != undefined && (
                          <span className="form-error">
                            {errors.email?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appraisal-form-detail vehicle-details">
                <div className="appraisal-form-head">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="card-title">Vehicle</h5>
                    </div>
                    <div className="col-auto">
                      <img src={carfaxLogo} alt="" />
                    </div>
                  </div>
                </div>
                <div className="appraisal-form-inner mb-24">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Vin</label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={17}
                          {...register("vin")}
                        />
                        {errors.vin?.message != undefined && (
                          <span className="form-error">
                            {errors.vin?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Year</label>
                        <select
                          className="form-select"
                          {...register("year")}
                          disabled={autoFill}
                          onChange={(e) =>
                            yearChange(
                              e.target.value,
                              e.target.options[e.target.selectedIndex].text
                            )
                          }
                        >
                          <option value="" selected={!autoFill}>
                            Select Year
                          </option>
                          {_.map(years, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.year?.message != undefined && (
                          <span className="form-error">
                            {errors.year?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Make</label>
                        {!autoFillMake && (
                          <select
                            className="form-select"
                            {...register("make")}
                            onChange={(e) =>
                              makeChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }
                          >
                            <option value="" selected={!autoFill}>
                              Select Make
                            </option>
                            {_.map(makes, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className="form-control"
                            {...register("make")}
                            disabled={autoFillMake}
                          />
                        )}
                        {errors.make?.message != undefined && (
                          <span className="form-error">
                            {errors.make?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Model</label>
                        {!autoFillMake && (
                          <select
                            className="form-select"
                            {...register("model")}
                            onChange={(e) =>
                              modelChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }
                          >
                            <option value="" selected={!autoFill}>
                              Select Model
                            </option>
                            {_.map(models, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className="form-control"
                            {...register("model")}
                          />
                        )}
                        {errors.model?.message != undefined && (
                          <span className="form-error">
                            {errors.model?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Trim</label>
                        {!autoFillMake && (
                          <select
                            className="form-select"
                            {...register("trim")}
                            onChange={(e) =>
                              trimChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }
                          >
                            <option value="" selected={!autoFill}>
                              Select Trim
                            </option>
                            {_.map(trims, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className="form-control"
                            {...register("trim")}
                          />
                        )}
                        {errors.trim?.message && (
                          <span className="form-error">
                            {errors.trim?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Mileage</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("mileage")}
                        />
                        {errors.mileage?.message != undefined && (
                          <span className="form-error">
                            {errors.mileage?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Price</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("price")}
                        />
                        {errors.price?.message != undefined && (
                          <span className="form-error">
                            {errors.price?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Exterior color</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("exterior_color")}
                        />
                        {errors.exterior_color?.message != undefined && (
                          <span className="form-error">
                            {errors.exterior_color?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Interior color</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("interior_color")}
                        />
                        {errors.interior_color?.message != undefined && (
                          <span className="form-error">
                            {errors.interior_color?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="appraisal-form-detail vehicle-photos">
                <div className="appraisal-form-head">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">Vehicle Photos</h5>
                    </div>
                    <div className="col-auto">
                      <div className="upload-button" {...getRootProps()}>
                        <i className="icon icon-upload"></i>
                        <span>Upload Photo</span>
                        <input {...getInputProps()} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="appraisal-form-inner">
                  <div className="row">
                    {fields.map((f, index) => (
                      <div
                        className="col-lg-3 col-sm-3 vehicleuplaodcol"
                        key={index}
                      >
                        <div className="vehicle-photo-wrap">
                          <img src={f.preview} alt="" className="img-fluid" />
                          <i
                            className="icon icon-close"
                            onClick={() => remove(index)}
                          ></i>
                        </div>
                      </div>
                    ))}
                    <div className="col-lg-3 col-sm-3 vehicleuplaodcol">
                      <div
                        className="vehicle-photos-upload"
                        {...getRootProps()}
                      >
                        <i className="icon icon-add-picture"></i>
                        <input {...getInputProps()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="new-appraisal-card-submit">
                <div className="row newcard-submitrow justify-content-center">
                  <div className="col-md-6 newcard-submitcol">
                    <button type="submit" className="btn btn-pink">
                      Submit the appraisal
                    </button>
                  </div>
                </div>
                {/* <p className="form-privacy-policy">
                  <a href="#" target="_blank">
                    termes and conditions
                  </a>
                  r sit amet, consectetur adipiscing elit. Adipiscing <br />
                  nunc, mattis facilisis neque, magnis nulla donec morbi.
                </p> */}
              </div>
            </form>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default AppraisalCreate;
