import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";

import api from "../../api";
import bannerImg from "../../assets/img/login-banner.jpg";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";

const validationSchema = yup.object().shape({
  email: yup.string().email().max(255).required("Email is required."),
  password: yup.string().max(255).required("Password is required."),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate(state?.path || routes.Dashboard);
    }
  }, [state, navigate]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    await api
      .post("api/login", formValue)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          authCtx.login(response.data.data);
          navigate(state?.path || routes.Dashboard);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <section className="login-section">
      <div className="login-upper-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="login-inner">
                <h2 className="login-title">
                  Log in to your Ilusso Vehicle appraisal system
                </h2>
                <p className="login-text">
                  Enter your credentials to log in to your account
                </p>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control email"
                              placeholder="Your email"
                              {...register("email")}
                            />
                            {errors.email?.message != undefined && (
                              <span className="form-error">
                                {errors.email?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group-with-password">
                              <input
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control password"
                                placeholder="Password"
                                {...register("password")}
                              />
                              <i
                                onClick={showPasswordHandler}
                                className="icon icon-eye"
                              ></i>
                            </div>
                            {errors.password?.message != undefined && (
                              <span className="form-error">
                                {errors.password?.message}
                              </span>
                            )}
                            <Link
                              to={routes.ForgotPassword}
                              className="login-form-trouble"
                            >
                              I have trouble with my login
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}
                          >
                            Log in
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <p className="login-form-register-link">
                            <i className="icon icon-product"></i> You are a
                            company!
                            <Link to={routes.Register}>Register Now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="login-image">
                <img src={bannerImg} alt="" />
                <div className="dashboard-card is-total-appraisals">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-total-appraisal"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count ffr">123</h5>
                      <span className="dashboard-card-label ffr">
                        Total Appraisals
                      </span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-card is-total-won">
                  <div className="dashboard-card-row">
                    <div className="dashboard-card-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="dashboard-card-text">
                      <h5 className="dashboard-card-count ffr">450</h5>
                      <span className="dashboard-card-label ffr">
                        Total Won
                      </span>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="total-appraisals-added-card">
                  <span className="total-appraisals-count">32</span>
                  <h5 className="total-appraisals-text">
                    Appraisal added today
                  </h5>
                  <p>Check the appraisal and give it your offers</p>
                  <div className="total-appraisals-button-wrap">
                    <Link to={routes.Login} className="btn btn-parrot">
                      Go to Appraisal
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-down-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="login-copyright">
                <span>©2022 ilusso | All rights Reserved</span>
                <span>Made with love by Moolay Media </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
