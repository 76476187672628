import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  const logoutHandler = () => {
    authCtx.logout();
    navigate(routes.Home);
  };
  return (
    <div
      className={`dashboard-sidebar ${themeCtx.isSidebarOpen ? "show" : ""}`}
    >
      <ul>
        <li>
          <NavLink to={routes.Dashboard}>
            <i className="icon icon-dashboard"></i> Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to={routes.Appraisals}>
            <i className="icon icon-appraisals"></i> Appraisals
          </NavLink>
        </li>
        <li>
          <NavLink to={routes.Reports}>
            <i className="icon icon-reports is-new"></i> Reports
          </NavLink>
        </li>
        <hr />
        {authCtx.userData?.user_type != 2 && (
          <li>
            <NavLink to={routes.Employees}>
              <i className="icon icon-employees"></i> Employees
            </NavLink>
          </li>
        )}
        {/* <li>
          <a href="#">
            <i className="icon icon-settings"></i> Settings
          </a>
        </li> */}
        <hr className="d-xl-none d-lg-block" />
        <li className="d-xl-none d-lg-block">
          <a href="#">Get Help</a>
        </li>
        <li className="d-xl-none d-lg-block">
          <a href="#">Contact Us</a>
        </li>
        <li className="d-xl-none d-lg-block">
          <a href="#">Go Pro</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="#" onClick={() => logoutHandler()}>
            <i className="icon icon-logout"></i> Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
