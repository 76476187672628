import React, { Fragment, useContext } from "react";
import ThemeContext from "../../context/themeContext";
import ContactUs from "../ContactUs";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

const Wrapper = ({ children }) => {
  const themeCtx = useContext(ThemeContext);
  return (
    <Fragment>
      <Navbar />
      <section className="dashboard-wrapper">
        <Sidebar />
        <div className="dashboard-inner">{children}</div>
        <div
          className={`dashboard-backdrop ${
            themeCtx.showDropShadow ? "show" : ""
          }`}
        ></div>
        <ContactUs />
      </section>
    </Fragment>
  );
};

export default Wrapper;
