import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import moment from "moment";
import downloadCsv from "download-csv";
import Swal from "../Swal";

const Reports = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);

  useEffect(() => {
    if (searchDebouncedValue != "") {
      fetchReports({ search: searchDebouncedValue, user_type: roleType }, 1);
    } else {
      fetchReports({ user_type: roleType }, 1);
    }
  }, [searchDebouncedValue, roleType]);

  async function fetchReports(formValue = {}, page) {
    setLoading(true);
    await api
      .post(`api/report/leads?page=${page}`, formValue, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchReports(
          {
            field_name: field,
            order_by: "asc",
            user_type: roleType,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchReports(
          {
            field_name: field,
            order_by: "desc",
            user_type: roleType,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchReports(
        {
          field_name: field,
          order_by: "desc",
          user_type: roleType,
        },
        page
      );
    }
  }

  const onPaginationFetch = (e) => {
    fetchReports(
      { field_name: sortField, order_by: sortType, user_type: roleType },
      e.selected + 1
    );
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    await api
      .post(
        "api/report/leads",
        {
          action: "export",
          field_name: sortField,
          order_by: sortType,
          user_type: roleType,
          search: searchDebouncedValue,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            return {
              name: item.name,
              role:
                (item.user_type == 0 && "Admin") ||
                (item.user_type == 1 && "Manager") ||
                (item.user_type == 2 && "Employee"),
              appraisals: item.appraisals,
              totalWon: item.totalWon,
              turnover: item.turnover,
              totalEarnings: item.totalEarnings,
            };
          });

          downloadCsv(
            exportData,
            {
              name: "Employee",
              role: "Role",
              appraisals: "No of Appraisal",
              totalWon: "Total Won",
              turnover: "Turn over",
              totalEarnings: "Total Bonus",
            },
            `report-${moment(currentDateTime).format("DD-MM-YYYY")}`
          );
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Wrapper>
        <div className="dashboard-count-filter dashboard-appraisals mb-24">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="page-title">Reports</h2>
              <p className="page-dec">
                In total there is <b>{pagination?.total_records}</b> reports
              </p>
            </div>
          </div>
        </div>
        <div className="dashboard-latest-appraisals">
          <div className="dashboard-card">
            <div className="dashboard-card-filter dashboard-count-filter">
              <div className="row align-items-center">
                <div className="col">
                  <div className="dashboard-navbar-search dashboardml">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {authCtx.userData?.user_type == 0 && (
                    <div className="dashboard-navbar-month dashboardml">
                      <div className="form-group">
                        <select
                          className="form-select"
                          onChange={(e) => setRoleType(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="1">Manager</option>
                          <option value="2">Employee</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-auto">
                  <div className="dashboard-export">
                    <button
                      type="button"
                      className="btn btn-pink"
                      onClick={() => exportHandler()}
                    >
                      <i className="icon icon-export"></i> Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-latest-apraisals table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th width="30%">
                      Employee{" "}
                      <span
                        className={`table-sort ${
                          sortField == "name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("name", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      Role{" "}
                      <span
                        className={`table-sort ${
                          sortField == "user_type" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "user_type",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      No of Appraisal{" "}
                      <span
                        className={`table-sort ${
                          sortField == "appraisals" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "appraisals",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="11%">
                      Total Won{" "}
                      <span
                        className={`table-sort ${
                          sortField == "totalWon" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "totalWon",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="10%">
                      Turn over{" "}
                      <span
                        className={`table-sort ${
                          sortField == "turnover" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "turnover",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="15%">
                      Total Bonus{" "}
                      <span
                        className={`table-sort ${
                          sortField == "totalEarnings" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "totalEarnings",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {responseData?.lead && responseData?.lead.length != 0 ? (
                    responseData?.lead.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <div className="table-user-image">
                            <img
                              src={data?.image != "" ? data?.image : userImg}
                              alt=""
                            />
                          </div>
                          {data?.name}
                        </td>
                        <td>
                          {data?.user_type == 0 ? "Admin" : ""}
                          {data?.user_type == 1 ? "Manager" : ""}
                          {data?.user_type == 2 ? "Employee" : ""}
                        </td>
                        <td>{data?.appraisals}</td>
                        <td>{data?.totalWon}</td>
                        <td>{data?.turnover}</td>
                        <td>{data?.totalEarnings}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={6}>No Reports Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
          <nav className="navigation pagination">
            <ReactPaginate
              breakLabel="..."
              onPageChange={onPaginationFetch}
              pageRangeDisplayed={2}
              pageCount={pagination?.total_pages}
              renderOnZeroPageCount={null}
              containerClassName="nav-links"
              activeLinkClassName="current"
              pageLinkClassName="page-numbers"
              previousLinkClassName="prev page-numbers"
              previousLabel={<i className="icon icon-arrow-left"></i>}
              nextLinkClassName="next page-numbers"
              nextLabel={<i className="icon icon-arrow-right"></i>}
            />
          </nav>
        )}
      </Wrapper>
    </>
  );
};

export default Reports;
