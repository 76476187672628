const routes = {
  Login: "/",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:id",
  Register: "/register",
  Dashboard: "/dashboard",
  Appraisals: "/appraisals",
  AppraisalCreate: "/appraisals/create",
  AppraisalEdit: "/appraisals/edit/:id",
  AppraisalView: "/appraisals/view/:id",
  AppraisalHistory: "/appraisals/history/:id",
  Reports: "/reports",
  Employees: "/employees",
  EmployeesCreate: "/employees/create",
  EmployeesCreatepassword: "/create-password/:id",
  EmployeesView: "/employees/view/:id",
  Profile: "/profile",
};
export default routes;
