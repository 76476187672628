import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import Wrapper from "../Wrapper";
import BiggestDeal from "./BiggestDeal";
import LatestAppraisals from "./LatestAppraisals";
import TopEmployees from "./TopEmployees";
import noAppraisalImg from "../../assets/img/no-appraisalimg.png";

const Dashboard = () => {
  const [responseData, setResponseData] = useState({});
  const [filterType, setFilterType] = useState(2);
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    const getresponseData = async () => {
      await api
        .post(
          "api/dashboard",
          { type: filterType },
          {
            headers: {
              Authorization: `Bearer ${authCtx?.userData?.token}`,
            },
          }
        )
        .then((res) => {
          setResponseData(res.data.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    };

    getresponseData();
  }, [filterType]);

  const filterOnChange = (e) => {
    setFilterType(e.target.value);
  };

  return (
    <Wrapper>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Dashboard</h2>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={filterOnChange}
                defaultValue={filterType}
              >
                <option value="1">This day</option>
                <option value="2">This week</option>
                <option value="3">This month</option>
                <option value="4">This year</option>
                <option value="5">Range</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-count-wrap mb-24">
        <div className="row">
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-total-won">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-won"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.totalWon != undefined
                      ? responseData?.dashboard?.totalWon
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Total Won</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-total-appraisals">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-total-appraisal"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.totalAppraisals != undefined
                      ? responseData?.dashboard?.totalAppraisals
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Total Appraisals</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-new-appraisal">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-new-appraisals"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.newAppraisals != undefined
                      ? responseData?.dashboard?.newAppraisals
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">New Appraisal</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-offer-made">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-offer-made"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.offerMade != undefined
                      ? responseData?.dashboard?.offerMade
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Offer Made</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "20%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-no-deals">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-no-deals"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.noDeal != undefined
                      ? responseData?.dashboard?.noDeal
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">No Deals</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "60%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <div className="dashboard-card is-working">
              <div className="dashboard-card-row">
                <div className="dashboard-card-icon">
                  <i className="icon icon-working"></i>
                </div>
                <div className="dashboard-card-text">
                  <h5 className="dashboard-card-count">
                    {responseData?.dashboard?.working != undefined
                      ? responseData?.dashboard?.working
                      : 0}
                  </h5>
                  <span className="dashboard-card-label">Working</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "40%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="10"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {authCtx.userData?.user_type != 2 && (
        <div className="dashboard-featured mb-24">
          <div className="row">
            <div className="col-xl-6">
              <TopEmployees />
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="total-appraisals-added-card dashboard-card">
                <span className="total-appraisals-count">
                  {responseData?.appraisalAddedToday != undefined
                    ? responseData?.appraisalAddedToday
                    : 0}
                </span>
                <h5 className="total-appraisals-text">Appraisal added today</h5>
                <p>Check the appraisal and give it your offers</p>
                <div className="total-appraisals-button-wrap">
                  <Link to={routes.Appraisals} className="btn btn-parrot">
                    Go to Appraisal
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              {responseData?.biggestDeal != undefined && (
                <BiggestDeal data={responseData?.biggestDeal} />
              )}
            </div>
          </div>
        </div>
      )}
      {responseData?.dashboard?.totalAppraisals != undefined &&
      responseData?.dashboard?.totalAppraisals != 0 ? (
        <LatestAppraisals />
      ) : (
        <div class="no-appraisalMain text-center">
          <div class="no-appraisalimg">
            <img src={noAppraisalImg} alt="" />
          </div>
          <div class="secHead text-center">
            <h4 class="secTitle size24">No Appraisal yet!</h4>
            <p>Start adding appraisals, it will be displayed here. </p>
          </div>
          <Link to={routes.AppraisalCreate} class="btn btn-pink">
            <i class="icon icon-add"></i> Add New Appraisal
          </Link>
        </div>
      )}
    </Wrapper>
  );
};

export default Dashboard;
