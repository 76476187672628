import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import vehicleImg from "../../assets/img/vehicle.jpg";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import api from "../../api";
import routes from "../../constants/routesConstants";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Swal from "../Swal";
import _ from "lodash";

const Appraisals = () => {
  const [responseData, setResponsedata] = useState({});
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (searchDebouncedValue != "") {
      fetchAppraisals({ search: searchDebouncedValue }, 1);
    } else {
      fetchAppraisals({}, 1);
    }
  }, [searchDebouncedValue]);

  async function fetchAppraisals(formValue = {}, page) {
    setLoading(true);
    await api
      .post(`api/leads?page=${page}`, formValue, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          navigate(state?.path || routes.Login);
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }
  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchAppraisals(
          {
            field_name: field,
            order_by: "asc",
          },
          page
        );
      } else {
        setSortType("desc");
        fetchAppraisals(
          {
            field_name: field,
            order_by: "desc",
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchAppraisals(
        {
          field_name: field,
          order_by: "desc",
        },
        page
      );
    }
  }

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/leads/delete-lead",
              { lead_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx?.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchAppraisals({}, 1);
                Swal.fire(response.data.message, "", "success");
              }
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire(
                "Opps!",
                "Sorry we are not able to complete your request at this time. Please try again later.",
                "error"
              );
            });
        }
      });
    }
    return false;
  };

  const onPaginationFetch = (e) => {
    fetchAppraisals(
      { field_name: sortField, order_by: sortType },
      e.selected + 1
    );
  };

  useEffect(() => {
    if (authCtx.userData?.user_type == 0) {
      api
        .post(
          `api/users/get-manager`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let managerArray = [];
            if (response.data.data.length > 0) {
              response.data.data.map((element) => {
                managerArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setManagers(managerArray);
            } else {
              setManagers([]);
            }
          } else {
            setManagers([]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  }, []);

  const onManagerChange = async (e) => {
    fetchAppraisals({ manager_user_id: e.target.value }, 1);
  };

  return (
    <>
      <Wrapper>
        <div className="dashboard-count-filter dashboard-appraisals  mb-24">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="page-title">Appraisals</h2>
              <p className="page-dec">
                In total there is <b>{pagination?.total_records}</b> appraisals
              </p>
            </div>
          </div>
        </div>
        <div className="dashboard-latest-appraisals">
          <div className="dashboard-card">
            <div className="dashboard-card-filter dashboard-count-filter">
              <div className="row align-items-center">
                <div className="col">
                  <div className="dashboard-navbar-search dashboardml">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {authCtx.userData?.user_type == 0 && (
                    <div className="dashboard-navbar-month dashboardml">
                      <div className="form-group">
                        <select
                          className="form-select"
                          onChange={(e) => onManagerChange(e)}
                        >
                          <option value="">Select Managers</option>
                          {_.map(managers, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="dashboard-latest-apraisals table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th width="25%">
                      Vehicle{" "}
                      <span
                        className={`table-sort ${
                          sortField == "model" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("model", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="10%">
                      Customer Name{" "}
                      <span
                        className={`table-sort ${
                          sortField == "first_name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "first_name",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      VIN Number{" "}
                      <span
                        className={`table-sort ${
                          sortField == "vin" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("vin", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="11%">
                      Status{" "}
                      <span
                        className={`table-sort ${
                          sortField == "status" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "status",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="10%">
                      Offer Amount{" "}
                      <span
                        className={`table-sort ${
                          sortField == "deals_price" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "deals_price",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      Submitted Date{" "}
                      <span
                        className={`table-sort ${
                          sortField == "created_at" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "created_at",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="15%">
                      Added by{" "}
                      <span
                        className={`table-sort ${
                          sortField == "name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("name", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData?.lead && responseData?.lead.length != 0 ? (
                    responseData?.lead.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <div className="table-vehicle-image">
                            <img
                              src={
                                data?.vehicleImage != ""
                                  ? data?.vehicleImage
                                  : vehicleImg
                              }
                              alt=""
                            />
                          </div>
                          {data?.year} {data?.make} {data?.model}
                        </td>
                        <td>
                          {data?.first_name} {data?.last_name}
                        </td>
                        <td>{data?.vin}</td>
                        <td>
                          {data?.status == 0 && (
                            <span className="status-new">New</span>
                          )}
                          {data?.status == 1 && (
                            <span className="status-pending">Working</span>
                          )}
                          {data?.status == 2 && (
                            <span className="status-pending">Offer Made</span>
                          )}
                          {data?.status == 3 && (
                            <span className="status-won">Deal Trade in</span>
                          )}
                          {data?.status == 4 && (
                            <span className="status-won">Deal Purchase</span>
                          )}
                          {data?.status == 5 && (
                            <span className="status-rejected">No Deal</span>
                          )}
                        </td>
                        <td>
                          {data?.deals_price != null
                            ? "$" + data?.deals_price
                            : "No Offer"}
                        </td>
                        <td>
                          {moment(data?.created_at).format("MMMM D, YYYY")}
                        </td>
                        <td>
                          <div className="table-user-image">
                            <img
                              src={
                                data?.userImage != ""
                                  ? data?.userImage
                                  : userImg
                              }
                              alt=""
                            />
                          </div>
                          {data?.userName}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn-icon"
                            onClick={() => deleteHandler(data?.id)}
                          >
                            <i className="icon icon-delete"></i>
                          </button>
                          {authCtx.userData?.user_type != 2 && (
                            <Link
                              to={`/appraisals/view/${data?.id}`}
                              className="btn-icon"
                            >
                              <i className="icon icon-edit"></i>
                            </Link>
                          )}
                          {authCtx.userData?.user_type != 0 &&
                            authCtx.userData?.user_type != 1 && (
                              <Link
                                to={`/appraisals/edit/${data?.id}`}
                                className="btn-icon"
                              >
                                <i className="icon icon-edit"></i>
                              </Link>
                            )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={7}>No Appraisals Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
          <nav className="navigation pagination">
            <ReactPaginate
              breakLabel="..."
              onPageChange={onPaginationFetch}
              pageRangeDisplayed={2}
              pageCount={pagination?.total_pages}
              renderOnZeroPageCount={null}
              containerClassName="nav-links"
              activeLinkClassName="current"
              pageLinkClassName="page-numbers"
              previousLinkClassName="prev page-numbers"
              previousLabel={<i className="icon icon-arrow-left"></i>}
              nextLinkClassName="next page-numbers"
              nextLabel={<i className="icon icon-arrow-right"></i>}
            />
          </nav>
        )}
      </Wrapper>
    </>
  );
};

export default Appraisals;
