import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import userImg from "../../assets/img/user.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(10, "Phone number is to long.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is invalid."
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const EmployeesView = () => {
  const authCtx = useContext(AuthContext);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);
  const [previewImage, setPreviewImage] = useState({ imageUrl: "" });
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [alertNotification, setAlertNotification] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    await api
      .post(
        "api/users/get-user",
        { user_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setResponseData(response.data.data);
        setValue("name", response.data.data?.name);
        setValue("mobile_no", response.data.data?.mobile_no);
        setValue("email", response.data.data?.email);
        if (response.data.data?.status == 1) {
          setIsUserActive(true);
        } else {
          setIsUserActive(false);
        }
        if (response.data.data?.email_notification == 1) {
          setEmailNotification(true);
        } else {
          setEmailNotification(false);
        }
        if (response.data.data?.sms_notification == 1) {
          setSmsNotification(true);
        } else {
          setSmsNotification(false);
        }
        if (response.data.data?.alert_notification == 1) {
          setAlertNotification(true);
        } else {
          setAlertNotification(false);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const updateUserStatus = async () => {
    await api
      .post(
        "api/users/update-status",
        { user_id: id, status: !isUserActive ? "1" : "0" },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          Swal.fire("User Status Updated!", response.data.message, "success");
          setIsUserActive(!isUserActive);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const uploadedImage = watch("image");
  useEffect(() => {
    if (uploadedImage != undefined && uploadedImage.length != 0) {
      setPreviewImage({ imageUrl: URL.createObjectURL(uploadedImage[0]) });
    }
  }, [uploadedImage]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formValue.mobile_no);
    formData.append("password", formValue.password);
    formData.append("confirm_password", formValue.confirm_password);
    formData.append("user_id", id);
    if (formValue.image != null) {
      formData.append("image", formValue.image[0]);
    }
    await api
      .post("api/users/update-user", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(
            "User Updated Successfully!",
            response.data.message,
            "success"
          );
          setIsEdit(false);
          getUserDetails();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const notificationChange = async (type) => {
    let formData = {
      user_id: id,
      name: responseData?.name,
      mobile_no: responseData?.mobile_no,
      email: responseData?.email,
    };
    if (type == "email_notification") {
      formData = {
        ...formData,
        email_notification: !emailNotification ? 1 : 0,
      };
    } else if (type == "sms_notification") {
      formData = {
        ...formData,
        sms_notification: !smsNotification ? 1 : 0,
      };
    } else if (type == "alert_notification") {
      formData = {
        ...formData,
        alert_notification: !alertNotification ? 1 : 0,
      };
    } else {
      formData = {};
    }
    await api
      .post("api/users/update-user", formData, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          getUserDetails();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="appraisal-head-wrap mb-34">
          <div className="row">
            <div className="col">
              <div className="appraisal-head-title align-items-start">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon icon-back-aero"></i>
                </button>
                <div className="page-head">
                  <h2 className="page-title">User Profile</h2>
                  <p className="page-dec">Fill the required informations</p>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="dashboard-switch-button dashboardml">
                <div className="switch-buttonmain">
                  <span className="switch-button-text ffr">Active</span>
                  <div
                    className="switch-button"
                    onClick={() => updateUserStatus()}
                  >
                    <input
                      type="checkbox"
                      hidden="hidden"
                      checked={isUserActive}
                    />
                    <label className="switch"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification edit-profile my-edit-profile mb-24">
          <div className="manager-details-inner mt-0">
            <div className="row align-items-center">
              <div className="col managercol">
                <div className="manager-details-user">
                  <div className="manager-image">
                    <img
                      src={
                        previewImage.imageUrl
                          ? previewImage.imageUrl
                          : responseData?.image != ""
                          ? responseData?.image
                          : userImg
                      }
                      alt=""
                    />
                    {isEdit && (
                      <>
                        <i className="icon icon-add-picture"></i>
                        <input type="file" {...register("image")} />
                      </>
                    )}
                  </div>
                  <div className="manager-info">
                    <h4>{responseData?.name}</h4>
                    <span>
                      {responseData?.email} • {responseData?.mobile_no}
                    </span>
                  </div>
                </div>
              </div>

              {isEdit ? (
                <div className="col-auto text-end managercol myprofilecol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-primary d-inline-flex align-items-center"
                  >
                    <i className="icon icon-save"></i> Save Changes
                  </button>
                </div>
              ) : (
                <div className="col-auto managercol">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setIsEdit(true)}
                  >
                    <i className="icon icon-edit"></i> Edit Profile
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="appraisal-form-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("name")}
                  />
                  {errors.name?.message != undefined && (
                    <span className="form-error">{errors.name?.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    disabled={!isEdit}
                    {...register("mobile_no")}
                  />
                  {errors.mobile_no?.message != undefined && (
                    <span className="form-error">
                      {errors.mobile_no?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    readOnly
                    {...register("email")}
                  />
                </div>
              </div>
              {isEdit && (
                <>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        disabled={!isEdit}
                        {...register("password")}
                      />
                      {errors.password?.message != undefined && (
                        <span className="form-error">
                          {errors.password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        disabled={!isEdit}
                        {...register("confirm_password")}
                      />
                      {errors.confirm_password?.message != undefined && (
                        <span className="form-error">
                          {errors.confirm_password?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="notification mb-24">
          <h5 className="notification-Title ffr">Notification preference</h5>
          <div className="dashboard-notification table-responsive">
            <table className="table" align="center">
              <thead>
                <tr>
                  <th width="40%"></th>
                  <th width="10%">Email</th>
                  <th width="11%">SMS</th>
                  <th width="29%">Alert (Notification bell)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Notifications</td>
                  <td>
                    <label className="customCheckbox">
                      <input
                        type="checkbox"
                        checked={emailNotification}
                        onChange={() =>
                          notificationChange("email_notification")
                        }
                      />
                      <span className="lable-text"></span>
                    </label>
                  </td>
                  <td>
                    <label className="customCheckbox">
                      <input
                        type="checkbox"
                        checked={smsNotification}
                        onChange={() => notificationChange("sms_notification")}
                      />
                      <span className="lable-text"></span>
                    </label>
                  </td>
                  <td>
                    <label className="customCheckbox">
                      <input
                        type="checkbox"
                        checked={alertNotification}
                        onChange={() =>
                          notificationChange("alert_notification")
                        }
                      />
                      <span className="lable-text"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EmployeesView;
