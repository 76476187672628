import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";

import AuthContext from "../../context/authContext";
import userImg from "../../assets/img/user.jpg";
import ReactPaginate from "react-paginate";
import moment from "moment";
import downloadCsv, { downloadCSV } from "download-csv";
import Swal from "../Swal";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";

const Employees = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);

  useEffect(() => {
    if (searchDebouncedValue != "") {
      fetchEmployees({ search: searchDebouncedValue, user_type: roleType }, 1);
    } else {
      fetchEmployees({ user_type: roleType }, 1);
    }
  }, [searchDebouncedValue, roleType]);

  async function fetchEmployees(formValue = {}, page) {
    setLoading(true);
    await api
      .post(`api/users?page=${page}`, formValue, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchEmployees(
          {
            field_name: field,
            order_by: "asc",
            user_type: roleType,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchEmployees(
          {
            field_name: field,
            order_by: "desc",
            user_type: roleType,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchEmployees(
        {
          field_name: field,
          order_by: "desc",
          user_type: roleType,
        },
        page
      );
    }
  }

  const onPaginationFetch = (e) => {
    fetchEmployees(
      { field_name: sortField, order_by: sortType, user_type: roleType },
      e.selected + 1
    );
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/users/delete-user",
              { user_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx?.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchEmployees({}, 1);
                Swal.fire("Deleted!", response.data.message, "success");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    await api
      .post(
        "api/users",
        {
          action: "export",
          field_name: sortField,
          order_by: sortType,
          user_type: roleType,
          search: searchDebouncedValue,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let exportData = response.data.data.map((item) => {
            return {
              name: item.name,
              role:
                (item.user_type == 0 && "Admin") ||
                (item.user_type == 1 && "Sales Manager") ||
                (item.user_type == 2 && "Sales Specialist(Service Writer)"),
              mobile_no: item.mobile_no,
              email: item.email,
              created_at: item.created_at,
            };
          });

          downloadCsv(
            exportData,
            {
              name: "Employee",
              role: "Role",
              mobile_no: "Phone",
              email: "Email",
              created_at: "Joining Date",
            },
            `employees-${moment(currentDateTime).format("DD-MM-YYYY")}`
          );
        } else {
          Swal.fire({
            title: "Opps!",
            icon: "error",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Wrapper>
        <div className="dashboard-count-filter dashboard-appraisals  mb-24">
          <div className="row align-items-center">
            <div className="col">
              <h2 className="page-title">Employees</h2>
              <p className="page-dec">
                In total there is <b>{pagination?.total_records}</b> employees
              </p>
            </div>
          </div>
        </div>
        <div className="dashboard-latest-appraisals">
          <div className="dashboard-card">
            <div className="dashboard-count-filter dashboard-card-filter">
              <div className="row align-items-center">
                <div className="col">
                  <div className="dashboard-navbar-search dashboardml">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  {authCtx.userData?.user_type == 0 && (
                    <div className="dashboard-navbar-month dashboardml">
                      <div className="form-group">
                        <select
                          className="form-select"
                          onChange={(e) => setRoleType(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="1">Manager</option>
                          <option value="2">Employee</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-auto">
                  <div className="dashboard-export">
                    <Link to={routes.EmployeesCreate} className="btn btn-pink">
                      <i className="icon icon-add"></i> Add Employee
                    </Link>
                    <button
                      type="button"
                      className="btn btn-pink"
                      onClick={() => exportHandler()}
                    >
                      <i className="icon icon-export"></i> Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-latest-apraisals table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th width="30%">
                      Employee{" "}
                      <span
                        className={`table-sort ${
                          sortField == "name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("name", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      Role{" "}
                      <span
                        className={`table-sort ${
                          sortField == "user_type" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "user_type",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="12%">
                      Phone Number{" "}
                      <span
                        className={`table-sort ${
                          sortField == "mobile_no" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "mobile_no",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="11%">
                      Email{" "}
                      <span
                        className={`table-sort ${
                          sortField == "email" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("email", sortField, sortType, pagination.current)
                        }
                      ></span>
                    </th>
                    <th width="10%">
                      Joining Date{" "}
                      <span
                        className={`table-sort ${
                          sortField == "created_at" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "created_at",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }
                      ></span>
                    </th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {responseData?.user && responseData?.user.length != 0 ? (
                    responseData?.user.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <div className="table-user-image">
                            <img
                              src={
                                data?.image != "" && data?.image != null
                                  ? data?.image
                                  : userImg
                              }
                              alt=""
                            />
                          </div>
                          {data?.name}
                        </td>
                        <td>
                          {data?.user_type == 0 ? "Admin" : ""}
                          {data?.user_type == 1 ? "Sales Manager" : ""}
                          {data?.user_type == 2 ? "Employee" : ""}
                        </td>
                        <td>{data?.mobile_no}</td>
                        <td>{data?.email}</td>
                        <td>
                          {moment(data?.created_at).format("MMMM D, YYYY")}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn-icon"
                            onClick={() => deleteHandler(data?.id)}
                          >
                            <i className="icon icon-delete"></i>
                          </button>
                          <Link
                            to={`/employees/view/${data?.id}`}
                            className="btn-icon"
                          >
                            <i className="icon icon-edit"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={6}>No Reports Found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
          <nav className="navigation pagination">
            <ReactPaginate
              breakLabel="..."
              onPageChange={onPaginationFetch}
              pageRangeDisplayed={2}
              pageCount={pagination?.total_pages}
              renderOnZeroPageCount={null}
              containerClassName="nav-links"
              activeLinkClassName="current"
              pageLinkClassName="page-numbers"
              previousLinkClassName="prev page-numbers"
              previousLabel={<i className="icon icon-arrow-left"></i>}
              nextLinkClassName="next page-numbers"
              nextLabel={<i className="icon icon-arrow-right"></i>}
            />
          </nav>
        )}
      </Wrapper>
    </>
  );
};

export default Employees;
