import React, { useContext, useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import _ from "lodash";
import api from "../../api";
import { useFieldArray, useForm } from "react-hook-form";
import AuthContext from "../../context/authContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import carfaxLogo from "../../assets/img/carfax.png";
import userImg from "../../assets/img/user.jpg";
import { useDropzone } from "react-dropzone";

import routes from "../../constants/routesConstants";
import moment from "moment";
import Swal from "../Swal";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const validationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is invalid."
    )
    .min(10, "Phone number is to short.")
    .max(10, "Phone number is to long.")
    .required("Phone number is required."),
  vin: yup
    .string()
    .required("VIN number is required")
    .min(17, "Please Enter Minimum 17 Characters.")
    .max(17, "Please Enter Maximum 17 Characters."),
  year: yup.string().required("Year is required."),
  make: yup.string().required("Make is required."),
  model: yup.string().required("Model is required."),
  trim: yup.string().required("Trim is required."),
  mileage: yup.string().required("Mileage is required."),
});

const validationSchema2 = yup.object().shape({
  lead_notes: yup.string().required("Note Message is required."),
});

const AppraisalEdit = () => {
  const [loading, setLoading] = useState(false);
  const [statusClass, setStatusClass] = useState(null);
  const [responseData, setResponseData] = useState({});
  const [noteId, setNoteId] = useState(null);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [fieldLoading, setfieldLoading] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [autoFillMake, setAutoFillMake] = useState(false);
  const countRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();

  useEffect(() => {
    getLeadDetails();
  }, []);

  const getLeadDetails = async () => {
    await api
      .post(
        "api/leads/get-lead",
        { lead_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((res) => {
        setResponseData(res.data.data);
        setAutoFill(true);
        setAutoFillMake(true);
        setValue("first_name", res.data.data?.lead?.first_name);
        setValue("last_name", res.data.data?.lead?.last_name);
        setValue("email", res.data.data?.lead?.email);
        setValue("mobile_no", res.data.data?.lead?.mobile_no);
        setValue("deals_date", res.data.data?.lead?.deals_date);
        setValue("deals_notes", res.data.data?.lead?.deals_notes);
        setValue("deals_price", res.data.data?.lead?.deals_price);
        setValue("status", res.data.data?.lead?.status);
        setValue("make", res.data.data?.vehicle?.make);
        setValue("model", res.data.data?.vehicle?.model);
        setValue("trim", res.data.data?.vehicle?.trim);
        setValue("vin", res.data.data?.vehicle?.vin);
        setValue("year", res.data.data?.vehicle?.year);
        setValue("price", res.data.data?.vehicle?.price);
        setValue("mileage", res.data.data?.vehicle?.mileage);
        setValue("interior_color", res.data.data?.vehicle?.interior_color);
        setValue("exterior_color", res.data.data?.vehicle?.exterior_color);
      })
      .catch((error) => {
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  const {
    register,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async () => {
    const formValue = getValues();
    setLoading(true);

    let formData = new FormData();
    formData.append("lead_id", id);
    formData.append("first_name", formValue.first_name);
    formData.append("last_name", formValue.last_name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formValue.mobile_no);
    formData.append("vin", formValue.vin);
    formData.append("year", formValue.year);
    formData.append("make", formValue.make);
    formData.append("model", formValue.model);
    formData.append("trim", formValue.trim);
    formData.append("mileage", formValue.mileage);
    formData.append("price", formValue.price);
    formData.append("status", formValue.status);
    formData.append("interior_color", formValue.interior_color);
    formData.append("exterior_color", formValue.exterior_color);
    formData.append("deals_date", formValue.deals_date);
    formData.append("deals_notes", formValue.deals_notes);
    formData.append("deals_price", formValue.deals_price);

    let files = [];
    formValue.image.forEach((file) => {
      files.push(file.file);
    });
    for (var i = 0; i < files.length; i++) {
      formData.append("image[]", files[i]);
    }
    await api
      .post("api/leads/update-lead", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "image",
  });

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (image) =>
      image.forEach((file) => {
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      }),
  });

  const formData = watch();

  useEffect(() => {
    countRef.current = autoFill;
  }, [autoFill]);

  useEffect(() => {
    if (formData.vin) {
      if (formData.vin.length == 17) {
        setfieldLoading(true);
        api
          .get(`api/leads/chrome-data?vin=${formData.vin}`, {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          })
          .then((response) => {
            setfieldLoading(false);
            if (response.data.status) {
              if (response.data.data.year != "0") {
                if (response.data.data.year) {
                  setAutoFill(true);
                  setValue("year", response.data.data.year);
                }
                if (response.data.data.model != "") {
                  setValue("model", response.data.data.model);
                }
                if (response.data.data.trim != "") {
                  setValue("trim", response.data.data.trim);
                }
                if (response.data.data.make != "") {
                  setAutoFillMake(true);
                  setValue("make", response.data.data.make);
                } else {
                  setAutoFill(false);
                }
              } else {
                setAutoFill(false);
                setAutoFillMake(false);
              }
            } else {
              setAutoFill(false);
              setAutoFillMake(false);
            }
          })
          .catch((error) => {
            setfieldLoading(false);
          });
      } else {
        setAutoFill(false);
        setAutoFillMake(false);
      }
    }
  }, [formData.vin]);

  const yearChange = async (value, key) => {
    setfieldLoading(true);
    setValue("year", value);
    await api
      .get(`api/leads/chrome-data?getMake=true&modelYear=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        setModels([]);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setMakes([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
      });
  };

  const makeChange = async (value, key) => {
    setfieldLoading(true);
    makeRef.current = key;
    await api
      .get(
        `api/leads/chrome-data?getModel=true&modelYear=${formData.year}&modelMake=${value}`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setfieldLoading(false);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setModels([]);
      });
  };

  const modelChange = async (value, key) => {
    setfieldLoading(true);
    modelRef.current = key;
    await api
      .get(`api/leads/chrome-data?getTrim=true&modelId=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setTrims([]);
      });
  };

  const trimChange = (value, key) => {
    trimRef.current = key;
  };

  const deleteImageHandler = (image) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        api
          .post(
            "api/vehicles/delete-vehicle-image",
            {
              vehicle_id: responseData?.vehicle.id,
              lead_id: responseData?.vehicle.lead_id,
              image: image,
            },
            {
              headers: {
                Authorization: `Bearer ${authCtx.userData.token}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.data.status) {
              getLeadDetails();
              Swal.fire("Deleted!", response.data.message, "success");
            } else {
              Swal.fire("Try Again!", response.data.message, "info");
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue: setValue2,
  } = useForm({
    resolver: yupResolver(validationSchema2),
  });

  const onNoteSubmit = async (formValue) => {
    setLoading(true);
    formValue.lead_id = id;
    if (noteId != null) {
      formValue.note_id = noteId;
    }
    await api
      .post(
        `${noteId != null ? "api/notes/update-note" : "api/notes/add-note"}`,
        formValue,
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          getLeadDetails();
          setNoteId(null);
          reset2();
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const noteEditHandler = (data) => {
    setNoteId(data?.id);
    setValue2("lead_notes", data?.lead_notes);
  };

  const noteDeleteHandler = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        api
          .post(
            "api/notes/delete-note",
            {
              note_id: data?.id,
              lead_id: id,
            },
            {
              headers: {
                Authorization: `Bearer ${authCtx.userData?.token}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.data.status) {
              getLeadDetails();
              Swal.fire(response.data.message, "", "success");
            } else {
              Swal.fire(response.data.message, "", "info");
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          });
      }
    });
  };

  const noteCancelHandler = () => {
    setNoteId(null);
  };

  useEffect(() => {
    if (formData.status == "0") {
      setStatusClass("status-new");
    } else if (formData.status == "1" || formData.status == "2") {
      setStatusClass("status-pending");
    } else if (formData.status == "3" || formData.status == "4") {
      setStatusClass("status-won");
    } else if (formData.status == "5") {
      setStatusClass("status-rejected");
    } else {
      setStatusClass(null);
    }
  }, [formData.status]);

  return (
    <>
      <Wrapper>
        <div class="appraisal-head-wrap mb-24">
          <div class="row flex-row-reverse">
            <div class="col-auto">
              <div class="appraisal-head-buttons">
                <Link
                  to={routes.Appraisals}
                  className="btn btn-outline-secondary"
                >
                  Go Back
                </Link>
                <button
                  type="button"
                  className="btn btn-outline-primary d-inline-flex align-items-center"
                  onClick={() => onSubmit()}
                  disabled={loading}
                >
                  <i className="icon icon-save"></i> Save Changes
                </button>
              </div>
            </div>
            <div class="col">
              <div class="appraisal-head-title align-items-start">
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => navigate(-1)}
                >
                  <i className="icon icon-back-aero"></i>
                </button>
                <div class="page-head">
                  <h2 class="page-title">Appraisal Details</h2>
                  <p class="page-dec">
                    <b>#{id}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="appraisal-detailsMain">
          <div class="row">
            <div class="col-xxl-6 col-xl-12 col-lg-12 appraisalcol">
              <div class="dashboard-card">
                <div class="appraisal-form-detail customer-details">
                  <div class="appraisal-form-head">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title">Customers</h5>
                      </div>
                    </div>
                  </div>
                  <div class="appraisal-form-inner mb-24">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">First Name</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("first_name")}
                          />
                          {errors.first_name?.message != undefined && (
                            <span className="form-error">
                              {errors.first_name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Last Name</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("last_name")}
                          />
                          {errors.last_name?.message != undefined && (
                            <span className="form-error">
                              {errors.last_name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Phone</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("mobile_no")}
                          />
                          {errors.mobile_no?.message != undefined && (
                            <span className="form-error">
                              {errors.mobile_no?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Email</label>
                          <input
                            className="form-control"
                            type="email"
                            {...register("email")}
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appraisal-form-detail vehicle-details">
                  <div class="appraisal-form-head">
                    <div class="row align-items-center">
                      <div class="col">
                        <h5 class="card-title">Vehicle</h5>
                      </div>
                      <div class="col-auto">
                        <img src={carfaxLogo} alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="appraisal-form-inner mb-24">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Vin</label>
                          <input
                            className="form-control"
                            type="text"
                            maxLength={17}
                            {...register("vin")}
                          />
                          {errors.vin?.message != undefined && (
                            <span className="form-error">
                              {errors.vin?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Year</label>
                          <select
                            className="form-select"
                            {...register("year")}
                            disabled={autoFill}
                            onChange={(e) =>
                              yearChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }
                          >
                            <option value="" selected={!autoFill}>
                              Select Year
                            </option>
                            {_.map(years, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.year?.message != undefined && (
                            <span className="form-error">
                              {errors.year?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Make</label>
                          {!autoFillMake && (
                            <select
                              className="form-select"
                              {...register("make")}
                              onChange={(e) =>
                                makeChange(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex].text
                                )
                              }
                            >
                              <option value="" selected={!autoFill}>
                                Select Make
                              </option>
                              {_.map(makes, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          )}
                          {autoFillMake && (
                            <input
                              className="form-control"
                              {...register("make")}
                              disabled={autoFillMake}
                            />
                          )}
                          {errors.make?.message != undefined && (
                            <span className="form-error">
                              {errors.make?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Model</label>
                          {!autoFillMake && (
                            <select
                              className="form-select"
                              {...register("model")}
                              onChange={(e) =>
                                modelChange(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex].text
                                )
                              }
                            >
                              <option value="" selected={!autoFill}>
                                Select Model
                              </option>
                              {_.map(models, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          )}
                          {autoFillMake && (
                            <input
                              className="form-control"
                              {...register("model")}
                            />
                          )}
                          {errors.model?.message != undefined && (
                            <span className="form-error">
                              {errors.model?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Trim</label>
                          {!autoFillMake && (
                            <select
                              className="form-select"
                              {...register("trim")}
                              onChange={(e) =>
                                trimChange(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex].text
                                )
                              }
                            >
                              <option value="" selected={!autoFill}>
                                Select Trim
                              </option>
                              {_.map(trims, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          )}
                          {autoFillMake && (
                            <input
                              className="form-control"
                              {...register("trim")}
                            />
                          )}
                          {errors.trim?.message != undefined && (
                            <span className="form-error">
                              {errors.trim?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Mileage</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("mileage")}
                          />
                          {errors.mileage?.message != undefined && (
                            <span className="form-error">
                              {errors.mileage?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Price</label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("price")}
                          />
                          {errors.price?.message != undefined && (
                            <span className="form-error">
                              {errors.price?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Exterior color
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("exterior_color")}
                          />
                          {errors.exterior_color?.message != undefined && (
                            <span className="form-error">
                              {errors.exterior_color?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Interior color
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("interior_color")}
                          />
                          {errors.interior_color?.message != undefined && (
                            <span className="form-error">
                              {errors.interior_color?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="appraisal-form-detail vehicle-photos">
                  <div className="appraisal-form-head">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Vehicle Photos</h5>
                      </div>
                      <div className="col-auto">
                        <div className="upload-button" {...getRootProps()}>
                          <i className="icon icon-upload"></i>
                          <span>Upload Photo</span>
                          <input {...getInputProps()} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="appraisal-form-inner">
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 vehicleuplaodcol">
                        <div
                          className="vehicle-photos-upload"
                          {...getRootProps()}
                        >
                          <i className="icon icon-add-picture"></i>
                          <input {...getInputProps()} />
                        </div>
                      </div>
                      {fields.map((f, index) => (
                        <div
                          className="col-lg-3 col-sm-3 vehicleuplaodcol"
                          key={index}
                        >
                          <div className="vehicle-photo-wrap">
                            <img src={f.preview} alt="" className="img-fluid" />
                            <i
                              className="icon icon-close"
                              onClick={() => remove(index)}
                            ></i>
                          </div>
                        </div>
                      ))}
                      {responseData?.vehicle?.image.map((f, index) => (
                        <div
                          className="col-lg-3 col-sm-3 vehicleuplaodcol"
                          key={index}
                        >
                          <div className="vehicle-photo-wrap">
                            <img src={f} alt="" className="img-fluid" />
                            <i
                              className="icon icon-close"
                              onClick={() =>
                                deleteImageHandler(f.replace(/^.*[\\\/]/, ""))
                              }
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div class="col-xxl-6 col-xl-12 col-lg-12 appraisalcol">
              <div class="dashboard-card">
                <div class="appraisal-form-detail offer-notes">
                  <div class="appraisal-form-head mt-0">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title">Notes</h5>
                      </div>
                    </div>
                  </div>
                  <div className="offer-notes-inner">
                    {responseData?.note != undefined &&
                      responseData?.note.map((data, index) => (
                        <div className="offer-note" key={index}>
                          <div className="row">
                            <div className="col-auto">
                              <div className="offer-note-image">
                                <img
                                  src={
                                    data?.userImage != ""
                                      ? data?.userImage
                                      : userImg
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="offer-note-head">
                                <div className="offer-note-info">
                                  <p>{data?.userName}</p>
                                  <span>
                                    {moment(data?.created_at)
                                      .startOf("day")
                                      .fromNow()}
                                  </span>
                                </div>
                                {(data?.user_id == authCtx?.userData?.id ||
                                  authCtx?.userData?.user_type == 0) && (
                                  <div className="noted-action-buttons">
                                    <button
                                      type="button"
                                      className="btn-icon"
                                      onClick={() => noteDeleteHandler(data)}
                                    >
                                      <i className="icon icon-delete-2"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-icon"
                                      onClick={() => noteEditHandler(data)}
                                    >
                                      <i className="icon icon-edit"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="offer-note-detail">
                                <p>{data?.lead_notes}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="offer-note-input">
                    <form onSubmit={handleSubmit2(onNoteSubmit)}>
                      <div className="row g-0 align-items-center">
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Note"
                              {...register2("lead_notes")}
                            />
                            {errors2.lead_notes?.message != undefined && (
                              <span className="form-error">
                                {errors2.lead_notes?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-auto">
                          {noteId != null ? (
                            <>
                              <button
                                className="btn-icon"
                                type="button"
                                onClick={() => noteCancelHandler()}
                              >
                                <i className="icon icon-close"></i>
                              </button>
                              <button className="btn-icon ms-2" type="submit">
                                <i className="icon icon-save"></i>
                              </button>
                            </>
                          ) : (
                            <button className="btn-icon" type="submit">
                              <i className="icon icon-send"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default AppraisalEdit;
