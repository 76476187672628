import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import ilussoLogo from "../../assets/img/logo.svg";
import routes from "../../constants/routesConstants";

const validationSchema = yup.object().shape({
  name: yup.string().max(255).required("Full Name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  password: yup
    .string()
    .min(6, "Password must be 6 characters long")
    .max(255)
    .required("Password is required."),
  confirmation_password: yup
    .string()
    .min(6, "Password must be 6 characters long")
    .required("Confirm Password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const EmployeesCreatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    api
      .post(`api/users/get-user-details`, { user_id: id })
      .then((response) => {
        setValue("name", response.data.data?.name);
        setValue("email", response.data.data?.email);
        setResponseData(response.data.data);
      })
      .catch((error) => {
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  }, []);

  const onSubmit = async (formValue) => {
    formValue.user_id = id;
    setLoading(true);
    await api
      .post("api/user/reset-password", formValue)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire("Account Created Successfully!", "", "success");
        } else {
          Swal.fire(response.data.message, "", "info");
        }
        navigate(routes.Login);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Opps!",
          "Sorry we are not able to complete your request at this time. Please try again later.",
          "error"
        );
      });
  };

  return (
    <section className="reset-password-section user-reset-password-section">
      <div className="login-upper-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5">
              <div className="login-inner">
                <h2 className="login-title">
                  Create an account in ilusso appraisal system{" "}
                </h2>
                <p className="login-text">
                  Please make sure to fill in all the required fields to
                  complete your registration.
                </p>
                <div className="login-form">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Your workspace{" "}
                            </label>
                            <div className="form-control disabled">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="inputflex inputField"
                                  disabled
                                  value={responseData?.businessName}
                                />
                                <span className="inputimg">
                                  <img src={ilussoLogo} alt="" />
                                </span>
                              </div>
                            </div>
                            <p className="form-group-dec">
                              You are invited by manager to join this space{" "}
                              <a href="#" className="login-form-trouble">
                                <b>know more</b>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Position</label>
                            <input
                              type="text"
                              className="form-control"
                              value={`${
                                responseData?.user_type == 1
                                  ? "Manager"
                                  : "Employee"
                              }`}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Full Name <span>*</span>
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                {...register("name")}
                              />
                              {errors.name?.message != undefined && (
                                <span className="form-error">
                                  {errors.name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">Email</label>
                            <div className="position-relative">
                              <input
                                type="email"
                                className="form-control email"
                                placeholder="Your email"
                                {...register("email")}
                                disabled
                              />
                              {errors.email?.message != undefined && (
                                <span className="form-error">
                                  {errors.email?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label">
                              Create your own Password <span>*</span>
                            </label>
                            <div className="form-group-with-password">
                              <input
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control password"
                                placeholder="Use strong password"
                                {...register("password")}
                              />
                              <i
                                className="icon icon-eye"
                                onClick={showPasswordHandler}
                              ></i>
                              {errors.password?.message != undefined && (
                                <span className="form-error">
                                  {errors.password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="form-group-with-password">
                              <input
                                type="password"
                                className="form-control password"
                                placeholder="Confirm Password"
                                {...register("confirmation_password")}
                              />
                              {errors.confirmation_password?.message !=
                                undefined && (
                                <span className="form-error">
                                  {errors.confirmation_password?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-pink border-width-0"
                            disabled={loading}
                          >
                            Create account
                          </button>
                        </div>
                        <div className="col-lg-12">
                          <p className="login-form-login-link">
                            You have an account already{" "}
                            <Link to={routes.Login}>Sign in now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-down-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="login-copyright">
                <span className="me-0">
                  ©2022{" "}
                  <a href="#" target="_blank">
                    {" "}
                    ilusso
                  </a>{" "}
                  | All rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployeesCreatePassword;
