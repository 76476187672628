import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import Wrapper from "../Wrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().max(255).required("Email is required."),
  mobile_no: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(10, "Phone number is to long.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is invalid."
    ),
});

const EmployeesCreate = () => {
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const [managers, setManagers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx?.userData?.user_type == 0) {
      api
        .post(
          `api/users/get-manager`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            let managerArray = [];
            if (response.data.data.length > 0) {
              response.data.data.map((element) => {
                managerArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setManagers(managerArray);
            } else {
              setManagers([]);
            }
          } else {
            setManagers([]);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const employeeType = watch("user_type");

  const onSubmit = async (formValue) => {
    setLoading(true);
    await api
      .post("api/users/add-user", formValue, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(`/employees/view/${response.data.data?.id}`);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <Wrapper>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}
              >
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Add New User</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Invite Your Employee to join you here</h2>
          <p className="login-text">
            Enter your credentials to invite employee
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Full Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        {...register("name")}
                      />
                      {errors.name?.message != undefined && (
                        <span className="form-error">
                          {errors.name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {authCtx.userData?.user_type == 0 && (
                  <>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">
                          Position <span>*</span>
                        </label>
                        <select
                          className="form-control form-select"
                          {...register("user_type")}
                        >
                          <option value="1">Manager</option>
                          <option value="2">Employee</option>
                        </select>
                      </div>
                    </div>
                    {employeeType == 2 && (
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="form-label">
                            Select Manager <span>*</span>
                          </label>
                          <div className="position-relative">
                            <select
                              className="form-control form-select"
                              {...register("manager_id")}
                            >
                              <option value="">Select Manager</option>
                              {_.map(managers, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.manager_id?.message != undefined && (
                              <span className="form-error">
                                {errors.manager_id?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Phone number <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control phone"
                        {...register("mobile_no")}
                        maxLength={12}
                      />
                      {errors.mobile_no?.message != undefined && (
                        <span className="form-error">
                          {errors.mobile_no?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Email <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control email"
                        {...register("email")}
                      />
                      {errors.email?.message != undefined && (
                        <span className="form-error">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="notice">
                    <p>
                      Your employee will receive an email and an SMS with a link
                      to join this workspace.
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button type="submit" className="btn btn-pink">
                    <i className="icon icon-add-user"></i>
                    Add User
                  </button>
                </div>
                <div className="col-lg-12">
                  <p className="login-form-login-link">
                    <Link to={routes.Employees} className="cancel">
                      Cancel
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default EmployeesCreate;
