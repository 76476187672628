import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Appraisals from "../components/Appraisals";
import AppraisalCreate from "../components/Appraisals/create";
import AppraisalEdit from "../components/Appraisals/edit";
import AppraisalHistory from "../components/Appraisals/history";
import AppraisalView from "../components/Appraisals/view";
import Dashboard from "../components/Dashboard";
import Employees from "../components/Employees";
import EmployeesCreate from "../components/Employees/create";
import EmployeesCreatePassword from "../components/Employees/createPassword";
import EmployeesView from "../components/Employees/view";
import Login from "../components/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import ResetPassword from "../components/Login/ResetPassword";
import NotFound from "../components/NotFound";
import Profile from "../components/Profile";
import Register from "../components/Register";
import Reports from "../components/Reports";
import routes from "../constants/routesConstants";
import PrivateRoute from "./privateRoute";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.Login} element={<Login />} exact />
        <Route
          path={routes.ForgotPassword}
          element={<ForgotPassword />}
          exact
        />
        <Route path={routes.ResetPassword} element={<ResetPassword />} exact />
        <Route path={routes.Register} element={<Register />} exact />
        <Route
          path={routes.Dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.Appraisals}
          element={
            <PrivateRoute>
              <Appraisals />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.Reports}
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.Employees}
          element={
            <PrivateRoute>
              <Employees />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.EmployeesCreate}
          element={
            <PrivateRoute>
              <EmployeesCreate />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.EmployeesCreatepassword}
          element={<EmployeesCreatePassword />}
          exact
        />
        <Route
          path={routes.EmployeesView}
          element={
            <PrivateRoute>
              <EmployeesView />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.Profile}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.AppraisalCreate}
          element={
            <PrivateRoute>
              <AppraisalCreate />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.AppraisalEdit}
          element={
            <PrivateRoute>
              <AppraisalEdit />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.AppraisalView}
          element={
            <PrivateRoute>
              <AppraisalView />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={routes.AppraisalHistory}
          element={
            <PrivateRoute>
              <AppraisalHistory />
            </PrivateRoute>
          }
          exact
        />
        <Route path="*" element={<NotFound />} replace />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
