import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import Swal from "../Swal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import contactImg from "../../assets/img/contactimg.svg";

const validationSchema = yup.object().shape({
  subject: yup.string().max(255).required("Subject is required."),
  message: yup.string().max(255).required("Message is required."),
});

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponsedata] = useState(null);
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    if (themeCtx.isContactPopupShow) {
      getContact();
    }
  }, [themeCtx.isContactPopupShow]);

  const getContact = async () => {
    await api
      .post(
        "api/contact-us",
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((res) => {
        setResponsedata(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formValue) => {
    setLoading(true);
    await api
      .post("api/contact-us/send-inquiry", formValue, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          themeCtx.contactPopupToggle();
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <div
      className={`dashboard-popup get-help-popup contact-popup ${
        themeCtx.isContactPopupShow ? "show" : ""
      }`}
    >
      <div className="top-employee-popup dashboard-popup-inner">
        <button
          type="button"
          className="dashboard-popup-close"
          onClick={() => themeCtx.contactPopupToggle()}
        >
          <i className="icon icon-close"></i>
        </button>
        <div className="contactMian">
          <div className="row flex-row-reverse contactrow">
            <div className="col-sm-5 contactcol">
              <div className="contactimg">
                <img src={contactImg} alt="" />
              </div>
            </div>
            <div className="col-sm-7 contactcol">
              <div className="secHead">
                <h4 className="secTitle">Contact us</h4>
                <p>
                  Any questions, remarks or simply a message, we will be happy
                  to get back to you.
                </p>
                <a href="#" className="btn btn-pink">
                  I need help
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contactinfoMian">
          <div className="row flex-row-reverse contactinforow">
            <div className="col-sm-7 contactinfocol">
              <div className="contact-inner">
                <div className="secHead">
                  <h4 className="secTitle size24">Send us a message</h4>
                  <p>
                    Fill out the form and our team will get back to you within
                    24 hours.
                  </p>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form-inner">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Subject</label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter title here"
                                {...register("subject")}
                              />
                              {errors.subject?.message != undefined && (
                                <span className="form-error">
                                  {errors.subject?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Your Message</label>
                            <div className="position-relative">
                              <textarea
                                className="form-control textareaField"
                                placeholder="Write something here"
                                {...register("message")}
                              ></textarea>
                              {errors.message?.message != undefined && (
                                <span className="form-error">
                                  {errors.message?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-pink"
                            disabled={loading}
                          >
                            Send the message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-5 contactinfocol">
              <div className="contactdetails">
                <div className="secHead">
                  <h4 className="secTitle size24">Contact Information</h4>
                  <p>Reach us on social media or call us anytime.</p>
                </div>
                <div className="continfo">
                  <ul>
                    <li>
                      <a
                        className="infodetail"
                        href={`mailto:${responseData?.email}`}
                      >
                        <i className="icon icon-email"></i>{" "}
                        {responseData?.email}
                      </a>
                    </li>
                    <li>
                      <a
                        className="infodetail"
                        href={`tel:${responseData?.phone}`}
                      >
                        <i className="icon icon-call"></i> {responseData?.phone}
                      </a>
                    </li>
                    <li>
                      <p className="infodetail">
                        <i className="icon icon-map"></i>{" "}
                        {responseData?.address}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="socialMeadia">
                  <a
                    className="instagram"
                    target="_blank"
                    href={responseData?.instagram}
                  >
                    <i className="icon icon-instagram"></i>
                  </a>
                  <a
                    className="twitter"
                    target="_blank"
                    href={responseData?.twitter}
                  >
                    <i className="icon icon-twitter"></i>
                  </a>
                  <a
                    className="youtube"
                    target="_blank"
                    href={responseData?.youtube}
                  >
                    <i className="icon icon-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
