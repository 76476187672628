import React from "react";
import { Link } from "react-router-dom";
import userImg from "../../assets/img/user.jpg";
const BiggestDeal = (props) => {
  return (
    <div className="dashboard-biggest-deal dashboard-card">
      {props.data?.name != undefined && (
        <div className="dashboard-biggest-deal-user">
          <div className="deal-user-image">
            <img
              src={props.data?.image != "" ? props.data?.image : userImg}
              alt=""
            />
          </div>
          <div className="dashboard-biggest-deal-user-info">
            <h4>{props.data?.name}</h4>
            <span>Employee</span>
          </div>
        </div>
      )}
      <h2>
        {props.data?.name == undefined
          ? "No deal made yet!"
          : "Made the bigest deal this month!"}
      </h2>
      {props.data?.totalAmount != undefined && (
        <>
          <p>
            ${props.data?.totalAmount} USD • {props.data?.year}{" "}
            {props.data?.make} {props.data?.model} {props.data?.trim}
          </p>
          <div className="dashboard-biggest-deal-profile">
            <Link
              to={`/employees/view/${props.data?.id}`}
              className="btn btn-parrot"
            >
              See Profile
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default BiggestDeal;
