import React, { useState } from "react";

const ThemeContext = React.createContext({
  isSidebarOpen: false,
  toggleSidebar: () => {},
  showDropShadow: false,
  showDropShadowHandler: () => {},
  hideDropShadowHandler: () => {},
  isContactPopupShow: false,
  contactPopupToggle: () => {},
});

export const ThemeContextProvider = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showDropShadow, setShowDropShadow] = useState(false);
  const [isContactPopupShow, setIsContactPopupShow] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    setShowDropShadow(!showDropShadow);
  };

  const showDropShadowHandler = () => {
    setShowDropShadow(true);
  };

  const hideDropShadowHandler = () => {
    setShowDropShadow(false);
  };

  const contactPopupToggle = () => {
    setIsContactPopupShow(!isContactPopupShow);
    setShowDropShadow(!showDropShadow);
  };

  const contextValue = {
    isSidebarOpen: isSidebarOpen,
    toggleSidebar: toggleSidebar,
    showDropShadow: showDropShadow,
    showDropShadowHandler: showDropShadowHandler,
    hideDropShadowHandler: hideDropShadowHandler,
    isContactPopupShow: isContactPopupShow,
    contactPopupToggle: contactPopupToggle,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
